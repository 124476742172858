<template>
    <div class="homeBg">
        <div class="sideBox">
            <div class="boxCss">
                <div class="boxTitle">
                    <img src="../../assets/siteImg/title.png"/>
                    文物风险状态
                </div>
                <div class="boxMain">
                    <div class="leftTop">
                        <div class="chartBox">
                            <div class="centerChart">
                                <div>监测文物</div>
                                <div>{{ leftTopCount }}</div>
                            </div>
                            <pieChart 
                            :radius="['60%', '70%']"
                            :data="leftTopData" 
                            :itemStyle="{}"
                            :color="['#44BB9F','#FFED87','#FF8736','#FF2B2B']" 
                            :label="false" 
                            :legend="false"/>
                        </div>
                        <div class="chartRight">
                            <div class="CT-item" style="color: #44BB9F;">
                                <div class="CT-name" style="border-color: #44BB9F;">优</div>
                                <div class="CT-value" style="border-color: #44BB9F50;">{{ leftTopData[0].value }}</div>
                            </div>
                            <div class="CT-item" style="color: #E0D300;">
                                <div class="CT-name" style="border-color: #E0D300;">良</div>
                                <div class="CT-value" style="border-color: #E0D30050;">{{ leftTopData[1].value }}</div>
                            </div>
                            <div class="CT-item" style="color: #FF8736;">
                                <div class="CT-name" style="border-color: #FF8736;">中</div>
                                <div class="CT-value" style="border-color: #FF873650;">{{ leftTopData[2].value }}</div>
                            </div>
                            <div class="CT-item"  style="color: #FF2B2B;">
                                <div class="CT-name" style="border-color: #FF2B2B;">差</div>
                                <div class="CT-value" style="border-color: #FF2B2B50;">{{ leftTopData[3].value }}</div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
            <div class="boxCenter">
                <div class="boxTitle">
                    <img src="../../assets/siteImg/title.png"/>
                    环境指标超限状况
                </div>
                <div class="boxMain">
                    <div class="leftCenter">
                        <div class="operBar">
                            <el-radio-group v-model="typeSener" @change="changeRadio">
                                <el-radio-button label="02">温度</el-radio-button>
                                <el-radio-button label="01">湿度</el-radio-button>
                                <el-radio-button label="04">光照</el-radio-button>
                                <el-radio-button label="05">紫外</el-radio-button>
                                <el-radio-button label="03">CO₂</el-radio-button>
                                <el-radio-button label="06">VOC</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div class="TC-chart">
                            <wanringBar :salvProName="salvProName" :data="salvData"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxCss">
                <div class="boxTitle">
                    <img src="../../assets/siteImg/title.png"/>
                    功能应用统计
                </div>
                <div class="boxMain">
                    <div class="leftBotBox">
                        <div class="LB-box">
                            <div class="topText">采样次数 {{ sample }}</div>
                            <div class="botText">
                                <span class="botPoint"></span>
                                今日达标率{{siteRadio}}%
                            </div>
                        </div>
                        <div class="LB-box">
                            <div class="topText">设备数量 {{ devCount }}</div>
                            <div class="botText">
                                <span class="botPoint"></span>
                                今日在线率{{  ((devOnline/devCount)*100).toFixed(0)  }}%
                            </div>
                        </div>
                        <div class="LB-box">
                            <div class="topText">功能应用数</div>
                            <div class="botText2">
                                {{ onlineUse }}
                            </div>
                        </div>
                        <div class="LB-box">
                            <div class="topText">离线应用数</div>
                            <div class="botText2">
                                {{ offlineUse }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="centerBox">
            <div class="center-top">
                <div class="centerTop-Item">
                    <div class="imgCard"></div>
                    <div>风险预警数量</div>
                    <div class="value"><span>{{ warningCount }}</span>个</div>
                </div>
                <div class="centerTop-Item">
                    <div class="imgCard imgCard2"></div>
                    <div>风险评估数量</div>
                    <div class="value"><span>{{ leftTopCount }}</span>个</div>
                </div>
                <div class="centerTop-Item">
                    <div class="imgCard imgCard3"></div>
                    <div>风险处置数量</div>
                    <div class="value"><span>{{ alarmDealCount }}</span>个</div>
                </div>
            </div>
            <div class="center-mid">
                <div class="titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        {{ museumName }}
                        <el-select @change="changeBuilds" v-model="build" placeholder="请选择">
                            <el-option
                            v-for="item in builds"
                            :key="item.envId"
                            :label="item.envName"
                            :value="item.envId"
                            />
                        </el-select>
                    </div>
                    <div class="floorBox">
                        <div
                        @click="clickFloor(item.id)" 
                        :class="currentFloor==item.id?'floorName currentFloor':'floorName'"
                         v-for="(item,index) in floors" 
                         :key="index">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <div class="titleMsgBox">
                    <mapMode ref="mapMode" @mapEvent="mapEvent"/>
                </div>
            </div>
            <div class="center-bot">
                <div class="boxTitle2">
                    <img src="../../assets/siteImg/title.png"/>
                    告警详情
                </div>
                <div class="centerMainBot">
                    <div class="lineTitle">
                        <div class="td" style="color: #3891FF;">时间</div>
                        <div class="td" style="color: #3891FF;">位置</div>
                        <div class="td" style="color: #3891FF;">文物</div>
                        <div class="td" style="color: #3891FF;">原因</div>
                        <div class="td" style="color: #3891FF;">状态</div>
                    </div>
                    <div class="tableBox">
                        <div class="lineTitle"  v-for="(item,index) in alarmList" :key="index">
                            <div class="td">{{ item.createtime }}</div>
                            <div class="td">{{ item.positionname }}</div>
                            <div class="td">{{ item.relicname }}</div>
                            <div class="td">{{ item.detail }}</div>
                            <div class="td">
                                <el-tag v-if="item.status == 1" type="success">已处理</el-tag>
                                <el-tag v-if="item.status == 0" type="warning">未处理</el-tag>
                                <el-tag v-if="item.status == 2" type="danger">超时未处理</el-tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sideBox">
            <div class="rightBoxTop">
                <div class="boxTitle">
                    <img src="../../assets/siteImg/title.png"/>
                    气候环境状态
                </div>
                <div class="boxMain">
                    <div class="rightWSBox">
                        <div class="WBox">
                            <div class="img"></div>
                            <div class="imgtext">
                                <div>温度</div>
                                <div><span>{{ weatherData.temp }}</span> ℃</div>
                            </div>
                        </div>
                        <div class="SBox">
                            <div class="img"></div>
                            <div class="imgtext">
                                <div>湿度</div>
                                <div><span>{{ weatherData.humidity }}</span> %RH</div>
                            </div>
                        </div>
                    </div>
                    <div class="rightHJ">
                        风向：{{ weatherData.windDir }}<br/>
                        风速：{{ weatherData.windSpeed }}公里/小时<br/>
                        风力等级：{{ weatherData.windScale }}<br/>
                        大气压强：{{ weatherData.pressure }} 百帕<br/>
                        能见度：{{ weatherData.vis }}公里<br/>
                        露点温度:{{ weatherData.dew }} ℃<br/>
                        当前小时累计降水量：{{ weatherData.precip}}毫米
                    </div>
                    <div class="rightChart">
                        <div class="operBar">
                            <el-radio-group v-model="typeSener2" @change="changeRadio2">
                                <el-radio-button label="02">温度</el-radio-button>
                                <el-radio-button label="01">湿度</el-radio-button>
                                <el-radio-button label="04">光照</el-radio-button>
                                <el-radio-button label="05">紫外</el-radio-button>
                                <el-radio-button label="03">CO₂</el-radio-button>
                                <el-radio-button label="06">VOC</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div class="RC-chart">
                            <floatBar :yAxis="yAxis" :type="floatType" :wdminList="wdminList" :wdmaxList="wdmaxList"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightBoxBot">
                <div class="boxTitle">
                    <img src="../../assets/siteImg/title.png"/>
                    展厅预警对比
                </div>
                <div class="rightBoxchart">
                    <wbarChart :salvProName="warningX" :data="warningY" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import pieChart from './modules/piechart.vue'
import wanringBar from './modules/warningBar.vue'
import floatBar from './modules/float.vue'
import wbarChart from './modules/wbarChart.vue'
import mapMode from '@/components/leaflet/index.vue'
import { site_getAllShowData, site_getDynaData, site_weatherData, site_envList, site_envMap } from '@/api/site-home.js'
import { mapGetters } from 'vuex'
export default{
    components:{
        pieChart,
        wanringBar,
        floatBar,
        wbarChart,
        mapMode
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            alarmDealCount:0, //风险处置数量
            warningCount:0, //风险预警数量
            leftTopCount:0, //风险评估数量||监测文物数量
            leftTopData:[
                {name:'优',value:3},
                {name:'良',value:3},
                {name:'中',value:3},
                {name:'差',value:3},
            ],
            typeSener:'01',
            floatType:{
                label:'温度',
                unit:'℃'
            },
            senerSAll:[
                {label:'湿度',id:'01',unit:'%RH'},
                {label:'温度',id:'02',unit:'℃'},
                {label:'二氧化碳',id:'03',unit:'ppm'},
                {label:'光照度',id:'04',unit:'lx'},
                {label:'紫外辐射强度',id:'05',unit:'μw/cm2'},
                {label:'有机挥发物总量VOC（异丁烯）',id:'06',unit:'ppm'},
                {label:'有机污染物',id:'07',unit:'mg/m²'},
                {label:'无机污染物',id:'08',unit:'mg/m²'},
                {label:'含硫污染物',id:'09',unit:'mg/m²'},
            ],
            chartData:{},
            salvData:[30,20,25],
            salvProName:['展厅1','展厅2','展厅3'],
            alarmList:[],
            weatherData:{},
            siteRadio:0, //今日达标率->昨日达标率
            onlineUse:0, //功能应用数
            offlineUse:0, //离线应用数量
            sample:0, //采样次数
            devCount:0, //所有设备
            devOnline:0, //在线设备
            typeSener2:'01',
            chartDataMinMax:{},
            yAxis:['展厅1','展厅2','展厅3'],
            wdminList:[20, 22, 18],
            wdmaxList:[30, 28, 25],
            warningX:['展厅1','展厅2','展厅3'],
            warningY:[5,8,4],
            museumName:'重庆博物馆',
            build:null,
            builds:[],
            currentFloor:null,
            floors:[]
        }
    },
    mounted(){
        this.getMap()
        this.getBase()
    },
    methods:{
        changeRadio(val){
            this.typeSener = val
            this.salvProName = this.chartData[`${this.typeSener}y`].map(i=>{return i.exhibition })
            this.salvData = this.chartData[`${this.typeSener}y`].map(i=>{return i.radio })
        },
        changeRadio2(val){
            this.typeSener2 = val
            this.yAxis = this.chartDataMinMax[`${this.typeSener2}`].map(i=>{return i.exhibition })
            this.wdminList = this.chartDataMinMax[`${this.typeSener2}`].map(i=>{return i.min })
            this.wdmaxList = this.chartDataMinMax[`${this.typeSener2}`].map(i=>{return i.max })
            this.floatType = this.senerSAll.find(i=>{
                return i.id == val
            })
        },
        changeBuilds(value){
            this.build = value
            this.getFloor()
        },
        clickFloor(val){
            this.currentFloor = val
            this.getRoom()
        },
        mapEvent(e){
            if(e.type == 'click'){
                let userData = e.target.options.userData
                if(userData.envId){
                    this.$router.push({
                        path:'/View/siteView',
                        query:{
                            buildId:userData.envId
                        }
                    })
                }
            }
        },
        getMap(){
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.siteId
            }
            site_envMap(minQuery).then(res=>{
                this.builds = res.data.envs
                if(!this.builds||this.builds.length==0){
                    return
                }
                this.build = this.builds[0].envId
                this.getFloor()
            })
            let minQuery2 = {
            'siteId':this.siteId,
            'envId':this.siteId,
            'isSelf':true,
            'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                this.museumName = res.data[0].envName
            })
        },
        getFloor(){
            let minQuery = {
                'siteId':this.siteId,
                'envId':this.build,
                'isSelf':false,
                'isTree':true,
            }
            site_envList(minQuery).then(res=>{
                this.floors = res.data.map(i=>{
                    let obj = {
                        label:i.envName,
                        id:i.envId
                    }
                    return obj
                })
                if(this.floors.length>0){
                    this.currentFloor = this.floors[0].id
                    this.clickFloor(this.currentFloor)
                }
            })
        },
        getRoom(){
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.currentFloor
            }
            site_envMap(minQuery).then(res=>{
                let data = res.data.envs
                let src = `${window.g.imgPath}${res.data.layoutUrl}`
                let line = []
                data.forEach(el => {
                    let obj = {
                        type:'polygon',
                        points:JSON.parse(el.latlngs),
                        name:el.envName,
                        envId:el.envId 
                    }
                    line.push(obj)
                });
                this.$refs.mapMode.init(src, line)
            })
        },
        getBase(){
            site_getAllShowData(this.siteId).then(r=>{
                let countRisk = r.data.countRisk
                this.leftTopData[0].value = countRisk.excellent
                this.leftTopData[1].value = countRisk.good
                this.leftTopData[2].value = countRisk.average
                this.leftTopData[3].value = countRisk.poor
                this.leftTopCount = this.leftTopData.reduce((acc, current) => acc + current.value, 0)
                this.chartData = r.data.durationRatio
                this.salvProName = this.chartData[`${this.typeSener}y`].map(i=>{return i.exhibition })
                this.salvData = this.chartData[`${this.typeSener}y`].map(i=>{return i.radio })

                this.alarmList = r.data.alarm
                this.warningCount = r.data.alarmCount

                this.siteRadio = r.data.siteRadio
                this.onlineUse = r.data.onlineUse
                this.offlineUse = r.data.offlineUse
                this.devCount = r.data.devCount
                this.devOnline = r.data.devOnline

                this.chartDataMinMax = r.data.interval

                this.floatType = this.senerSAll.find(i=>{
                    return this.typeSener2 == i.id
                })
                this.yAxis = this.chartDataMinMax[`${this.typeSener2}`].map(i=>{return i.exhibition })
                this.wdminList = this.chartDataMinMax[`${this.typeSener2}`].map(i=>{return i.min })
                this.wdmaxList = this.chartDataMinMax[`${this.typeSener2}`].map(i=>{return i.max })
                
                this.warningX = r.data.countAlarm.map(i=>{return i.exhibition})
                this.warningY = r.data.countAlarm.map(i=>{return i.num})
                
            })
            site_getDynaData(this.siteId).then(r=>{
                this.sample = r.data.sample
                this.alarmDealCount = r.data.alarmDealCount
            })
            let query = {
                location:window.g.weatherAppid,
                key:window.g.weatherSecret
            }
            site_weatherData(query).then(r=>{
                this.weatherData = r.now
            })
        },
        
    }
}
</script>
<style lang="scss" scoped>
.homeBg{
    width: 100%;
    padding: 10px 0px 0px 0px;
    box-sizing: border-box;
    height: 900px;
    display: flex;
    justify-content: space-between;
}
.sideBox{
    width: 420px;
    height: 100%;
    padding-left: 3px;
    box-sizing: border-box;
    .boxMain{
        width: 100%;
        height: calc(100% - 60px);
        background:linear-gradient(180deg, #FFFFFF, #ECEDF5);
        box-shadow: 2px 0px 6px 0px #BDC6D6;
        border-bottom:1px solid #ddd;
        border-left:1px solid #ddd;
        border-radius: 10px;
        border-image: -webkit-linear-gradient(90deg,#549CFE,rgba(255, 255, 255, 0)) 1 1;
        border-image: -moz-linear-gradient(90deg,#549CFE,rgba(255, 255, 255, 0)) 1 1;
        border-image: linear-gradient(90deg,#549CFE,rgba(255, 255, 255, 0)) 1 1;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    .boxCss{
        width: 100%;
        height: 30%;
        .leftTop{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .chartBox{
                width: 50%;
                height: 100%;
                position: relative;
                .centerChart{
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    background-color: white;
                    text-align: center;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    top: 0px;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
            .chartRight{
                width: 50%;
                height: 70%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                .CT-item{
                    width: 80px;
                    background-image: url('../../assets/newSite/site-sign.png');
                    background-size: contain;
                    background-position: center 0px;
                    background-repeat: no-repeat;
                    padding: 15px 0px 0px 10px;
                    box-sizing: border-box;
                    color: #44BB9F;
                    .CT-name{
                        border-left:3px solid;
                        border-color: #44BB9F;
                        padding-left: 5px;
                        font-size: 14px;
                    }
                    .CT-value{
                        border-left:3px solid;
                        border-color: #44BB9F50;
                        padding-left: 5px;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }
        .leftBotBox{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center; 
            flex-wrap: wrap;
            .LB-box{
                width: 48%;
                height: 48%;
                background-image: url('../../assets/newSite/site-LBbg.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .topText{
                    color: #2687FF;
                    font-weight: bold;
                    border-bottom: 1px solid #cdcdd0;
                    padding: 0px 20px 5px 20px;
                    margin-bottom: 5px;
                }
                .botText{
                    font-size: 12px;
                    .botPoint{
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background-color: #16C987;
                        border-radius: 50%;
                    }
                }
                .botText2{
                    color: #2687FF;
                    font-weight: bold;
                }
            }
        }

        
    }
    .boxCenter{
        width: 100%;
        height: 40%;
        .leftCenter{
            width: 100%;
            height: 100%;
            .operBar{
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .TC-chart{
                width: 100%;
                height: calc(100% - 40px);
            }
        }
    }
    .rightBoxTop{
        width: 100%;
        height: 70%;
        .rightWSBox{
            height: 100px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .WBox{
                display: flex;
                justify-content: center;
                align-items: center;
                .img{
                   width: 60px;
                   height: 60px;
                   background-image: url('../../assets/newSite/site-wd.png'); 
                   background-size: 100% 100%;
                   background-repeat: no-repeat;
                   margin-right: 10px;
                }
                .imgtext{
                    span{
                        color: #FF9E23;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
            .SBox{
                display: flex;
                justify-content: center;
                align-items: center;
                .img{
                   width: 60px;
                   height: 60px;
                   background-image: url('../../assets/newSite/site-sd.png'); 
                   background-size: 100% 100%;
                   background-repeat: no-repeat;
                   margin-right: 10px;
                }
                .imgtext{
                    span{
                        color: #2F8CFF;
                        font-size: 20px;
                        font-weight: bold;
                    } 
                }
            }
        }
        .rightHJ{
            width: 100%;
            height: 170px;
            font-size: 14px; 
            line-height: 24px; 
            padding-left: 30px;
            box-sizing: border-box;
            color: #333333;
        }
        .rightChart{
            width: 100%;
            height: calc(100% - 270px);
            .operBar{
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 10px;
            }
            .RC-chart{
                width: 100%;
                height: calc(100% - 40px);
            }
        }
    }
    .rightBoxBot{
        width: 100%;
        height: 30%;
        .rightBoxchart{
            width: 100%;
            height: calc(100% - 60px);
            background:linear-gradient(180deg, #FFFFFF, #ECEDF5);
            box-shadow: 2px 0px 6px 0px #BDC6D6;
            border-bottom:1px solid #ddd;
            border-left:1px solid #ddd;
            border-radius: 10px;
            border-image: -webkit-linear-gradient(90deg,#549CFE,rgba(255, 255, 255, 0)) 1 1;
            border-image: -moz-linear-gradient(90deg,#549CFE,rgba(255, 255, 255, 0)) 1 1;
            border-image: linear-gradient(90deg,#549CFE,rgba(255, 255, 255, 0)) 1 1;
            box-sizing: border-box;
            margin-bottom: 10px;
        }
    }
}
.centerBox{
    width: calc(100% - 860px);
    height: 100%;
    .center-top{
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .centerTop-Item{
            color: white;
            width: 200px;
            height: 60px;
            background-image:linear-gradient(90deg, #BADEFF, #0066FF);
            border-radius: 60px;
            padding-left: 60px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .imgCard{
                width: 55px;
                position: absolute;
                height: 60px;
                left: -6px;
                top:0px;
                background-image: url('../../assets/newSite/site-count.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .imgCard2{
                background-image: url('../../assets/newSite/site-score.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .imgCard3{
                background-image: url('../../assets/newSite/site-oper.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .value{
                color: #15FCFF;
                span{
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            
        }
    }
    .center-mid{
        width: 100%;
        height: calc(70% - 110px);
        margin-bottom: 10px;
        background-color: white;
        .titleCss{
            width: 100%;
            height: 50px;
            padding-left: 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .floorBox{
                height: calc(100% - 50px);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 20px;
                box-sizing: border-box;
                .floorName{
                    font-size: 14px;
                    color: #333;
                    margin-right: 20px;
                }
                .currentFloor{
                    color: #0066FF;
                }

            }
        }
        .titleMsgBox{
            width: 100%;
            height: calc(100% - 50px);
            padding: 20px;
            box-sizing: border-box;
        }
    }
    .center-bot{
        width: 100%;
        height: 30%;
        .boxTitle2{
            width: 100%;
            height: 50px;
            background-image: url('../../assets/newSite/site-long.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding-left: 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img{
                margin-right: 4px;
            }
        }
        .centerMainBot{
            width: 100%;
            height: calc(100% - 60px);
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            padding: 20px  30px;
            box-sizing: border-box;
            .lineTitle{
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                .td{
                    width: 19%;
                    height: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .tableBox{
                width: 100%;
                height: calc(100% - 30px);
                overflow-y: auto;
            }
        }
    }
}

.boxTitle{
    width: 100%;
    height: 50px;
    background-image: url('../../assets/newSite/site-title.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
        margin-right: 4px;
    }
}

</style>